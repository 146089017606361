import { ExternalNavButton } from ".";
import _ from "lodash";
import { NavButton } from "./NavButton";
import { ShareModal } from "./ShareModal";
import { showShareModalAtom } from "../atoms";
import { useState } from "react";
import { useAtom } from "jotai";
import { NavLink, useLocation } from "react-router-dom";
import { TICKETS_URL } from "../constants";

import { ReactComponent as Logo } from "../assets/icons/logo.svg";
import { ReactComponent as MobileMenu } from "../assets/icons/button_mobile_menu.svg";
import { ReactComponent as CloseIcon } from "../assets/icons/button_close.svg";
import { ReactComponent as ShareIcon } from "../assets/icons/button_share.svg";

export const MobileNavbar: React.FC = () => {
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const [showShareModal, setShowShareModal] = useAtom(showShareModalAtom);

  const menuStyle = {
    height: "calc(100vh - 3.5rem)",
    width: "100%",
    paddingTop: "5%",
    paddingLeft: "12%",
  };

  const displayName = _.capitalize(_.trimStart(location.pathname, "./"));

  return (
    <>
      <div className="flex bg-black h-navbarHt w-full z-50">
        <ShareModal
          showModal={showShareModal}
          setShowExternal={setShowShareModal}
        />
        <div className="h-full flex flex-wrap content-center">
          <NavLink to="./">
            <div className="flex flex-wrap content-center font-bold text-white p-3">
              <Logo fill="currentColor" width="35px" height="35px" />
            </div>
          </NavLink>
        </div>
        <div className="h-full w-full flex flex-wrap items-center justify-end">
          <div id="pageDisplay" className="flex flex-grow text-white">
            <span className="w-full text-center font-bold text-2xl">
              {displayName}
            </span>
          </div>
          <button
            onClick={() => setShowMenu(!showMenu)}
            className="flex text-white hover:text-gray-300 items-center pr-2"
          >
            {!showMenu ? (
              <MobileMenu
                stroke="currentColor"
                strokeWidth={1.5}
                width="38px"
                height="38px"
              />
            ) : (
              <CloseIcon
                stroke="currentColor"
                strokeWidth={1.5}
                width="38px"
                height="38px"
              />
            )}
          </button>
        </div>
      </div>
      {showMenu && (
        <div className="relative z-50 w-full h-auto">
          <div
            className="absolute animate-fadein bg-black text-white"
            style={menuStyle}
          >
            <NavButton
              to="./map"
              location={location.pathname}
              mobile
              onClick={() => setShowMenu(false)}
            >
              Map
            </NavButton>
            <NavButton
              to="./trials"
              location={location.pathname}
              mobile
              onClick={() => setShowMenu(false)}
            >
              Trials
            </NavButton>
            <NavButton
              to="./courthouse"
              location={location.pathname}
              mobile
              onClick={() => setShowMenu(false)}
            >
              Courthouse
            </NavButton>
            <NavButton
              to="./discover"
              location={location.pathname}
              mobile
              onClick={() => setShowMenu(false)}
            >
              Discover
            </NavButton>
            <NavButton
              to="./education"
              location={location.pathname}
              mobile
              onClick={() => setShowMenu(false)}
            >
              Education
            </NavButton>
            <ExternalNavButton
              to={TICKETS_URL}
              mobile
              target="_blank"
              onClick={() => setShowMenu(false)}
            >
              Tickets
            </ExternalNavButton>
            <NavButton
              to="./about"
              location={location.pathname}
              mobile
              onClick={() => setShowMenu(false)}
            >
              About
            </NavButton>
            <button
              className="py-4"
              onClick={() => {
                setShowShareModal(!showShareModal);
                setShowMenu(false);
              }}
            >
              <ShareIcon
                className="text-white fill-current hover:text-btnTextHoverColor"
                width="50px"
                height="50px"
              />
            </button>
          </div>
        </div>
      )}
    </>
  );
};
