import React, { useState, useEffect, Fragment } from "react";
import {
  MapZoomButton,
  Content,
  Loader,
  ItemModal,
  FilterMenu,
} from "../components";
import axios from "axios";
import _ from "lodash";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { API, HEADERS } from "../constants";

import mapImage from "../assets/maps/map_3840px_prog.jpg";

import { ReactComponent as ZoomInIcon } from "../assets/icons/button_map_zoomIn.svg";
import { ReactComponent as ZoomOutIcon } from "../assets/icons/button_map_zoomOut.svg";
import { ReactComponent as POIIcon } from "../assets/icons/button_POI.svg";
import { ReactComponent as NextIcon } from "../assets/icons/button_next.svg";
import { ReactComponent as ExperienceIcon } from "../assets/icons/button_experience.svg";
import { ReactComponent as FilterLocationIcon } from "../assets/icons/button_filter_location.svg";
import { ReactComponent as FilterExperienceIcon } from "../assets/icons/button_filter_experience.svg";

const mapWidth = 4700;
const mapHeight = 4128;

interface IMarkerProps {
  xpos: number;
  ypos: number;
  nodeType: string;
  onClick: any; // function
  filtered: boolean;
}

const Marker: React.FC<IMarkerProps> = ({
  xpos,
  ypos,
  nodeType,
  onClick,
  filtered,
}) => {
  const style = { transform: `translate(${xpos}px, ${ypos}px)` };
  const className = `transition-opacity h-0 w-0 relative z-10 ${
    filtered ? "opacity-30" : "pointer-events-auto"
  }`;
  const btnBaseClass =
    "transition-colors transform -translate-x-1/2 -translate-y-full -my-2 bg-btnBgColor hover:bg-btnBgHover w-10 h-10 rounded-t-full rounded-bl-full transform rotate-45";

  return (
    <div className={className} style={style}>
      <button
        className={`${btnBaseClass} transition-transform hover:scale-110`}
        onClick={() => onClick()}
      >
        {nodeType === "Experience node" ? (
          <ExperienceIcon
            className="transform -rotate-45"
            stroke="white"
            fill="none"
          />
        ) : (
          <POIIcon
            className="transform -rotate-45"
            stroke="white"
            fill="none"
          />
        )}
      </button>
    </div>
  );
};

const TitleButton = ({
  xpos,
  ypos,
  scale,
  data,
  onClick,
}: {
  xpos: number;
  ypos: number;
  scale: number;
  data: any;
  onClick: any;
}) => {
  const translatedX = (data.position[0] / 100) * mapWidth * scale + xpos;
  const translatedY = (data.position[1] / 100) * mapHeight * scale + ypos;
  const style = { transform: `translate(${translatedX}px, ${translatedY}px)` };

  const flipThreshold = 180;
  const buttonYTranslate =
    translatedY < flipThreshold ? "translate-y-4" : "-translate-y-28";
  const lineYTranslate = translatedY < flipThreshold ? "" : "-translate-y-20";

  return (
    <div
      className="h-0 w-0 pointer-events-auto text-buttonSize text-black relative z-20 animate-fadein"
      style={style}
    >
      <div
        className={`absolute bg-black w-px h-8 transform ${lineYTranslate}`}
      />
      <button
        className={`relative transform transition-colors -translate-x-1/2 ${buttonYTranslate} flex flex-wrap py-3 w-max px-4 h-11 font-medium bg-white hover:bg-gray-300 content-center`}
        onClick={() => onClick()}
      >
        <div>{data.title}</div>
        <NextIcon
          stroke="black"
          width="25px"
          height="25px"
          fill="none"
          strokeWidth={2}
        />
      </button>
    </div>
  );
};

const Map: React.FC = () => {
  const wrapperStyle = {
    width: "100%",
    height: "100%",
  };

  const [minScale, setMinScale] = useState(1);
  const [displaySize, setDisplaySize] = useState([1024, 768]);
  const [initUpdate, setInitUpdate] = useState<boolean>(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [data, setData] = useState<any[] | null>(null);
  const [nodeData, setNodeData] = useState<any>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [target, setTarget] = useState<number[] | null>(null);
  const [targetIndex, setTargetIndex] = useState<number | null>(null);
  const [filter, setFilter] = useState<string | null>(null);
  const [mapFadedIn, setMapFadedIn] = useState(false);
  const [courthouseInfo, setCourthouseInfo] = useState<any>(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(API.MAP, HEADERS);
      const getData = response.data.map_nodes;
      setData(getData);

      // Get courthouse info
      getData.map((item: any, index: number) => {
        if (item.title === "Courthouse") {
          setCourthouseInfo({ index, ...item });
        }
      });

      const image = new Image();
      image.onload = () => {
        setMapLoaded(true);
        setInitUpdate(true);
      };
      image.src = mapImage;
    } catch (error) {
      console.error(error);
    }
  };

  const openModal = () => {
    setShowModal(true);
    setTargetIndex(null);
  };

  const refreshMap = () => {
    const mapRatio = mapWidth / mapHeight;
    const container = document.getElementById("container");
    const wd = container?.clientWidth || 1;
    const ht = container?.clientHeight || 1;
    const ratio = wd / ht;
    const newMinScale = ratio < mapRatio ? ht / mapHeight : wd / mapWidth;
    setDisplaySize([wd, ht]);
    setMinScale(newMinScale);
    setInitUpdate(true);
  };

  useEffect(() => {
    refreshMap();
  }, [mapLoaded]);

  useEffect(() => {
    if (!showModal) setNodeData(null);
  }, [showModal]);

  useEffect(() => {
    fetchData();
    window.addEventListener(
      "resize",
      _.debounce(() => refreshMap(), 100)
    );
    return () => {
      window.removeEventListener(
        "resize",
        _.debounce(() => refreshMap(), 100)
      );
    };
  }, []);

  const isLoading = !data;

  const filterOptions = [
    {
      label: "Locations",
      value: "Location node",
      Icon: FilterLocationIcon,
    },
    {
      label: "Experiences",
      value: "Experience node",
      Icon: FilterExperienceIcon,
    },
  ];

  const initScale = Math.min(minScale * 1.5, 1);
  return (
    <Content>
      <div className="w-full h-full" id="container">
        {mapLoaded && data && (
          <TransformWrapper
            initialScale={initScale}
            initialPositionX={displaySize[0] * 0.5 - mapWidth * initScale * 0.5}
            initialPositionY={
              displaySize[1] * 0.5 - mapHeight * initScale * 0.5
            }
            minScale={minScale}
            maxScale={1}
          >
            {({ setTransform, zoomIn, zoomOut, resetTransform, instance }) => {
              const xpos = instance.transformState.positionX;
              const ypos = instance.transformState.positionY;
              const curScale = instance.transformState.scale;
              const isMinScale = instance.transformState.scale === minScale;
              const isMaxScale = instance.transformState.scale === 1;

              const zoomToCourthouse = () => {
                if (courthouseInfo) {
                  const scale = curScale;
                  const markerX =
                    (courthouseInfo.position[0] / 100) * mapWidth * scale;
                  const markerY =
                    (courthouseInfo.position[1] / 100) * mapHeight * scale;
                  handleMarkerClicked(
                    courthouseInfo.index,
                    courthouseInfo,
                    -markerX,
                    -markerY
                  );
                }
              };

              const handleMarkerClicked = (
                index: number,
                node: any,
                xpos: number,
                ypos: number
              ) => {
                const x = xpos + displaySize[0] * 0.5;
                const y = ypos + displaySize[1] * 0.5;
                setTarget([x, y]);
                setNodeData(node);
                setTargetIndex(index);
              };

              if (initUpdate) {
                setInitUpdate(false);
                resetTransform();
              } else if (target !== null) {
                const mapImage = document.getElementById("mapImage");
                const wd = mapImage?.clientWidth || 0;
                const ht = mapImage?.clientHeight || 0;
                const xbound = displaySize[0] - wd * curScale;
                const ybound = displaySize[1] - ht * curScale;
                const newxpos = _.clamp(target[0], xbound, 0);
                const newypos = _.clamp(target[1], ybound, 0);
                setTransform(newxpos, newypos, curScale);
                setTarget(null);
              }

              return (
                <div className="w-full h-full relative bg-timeline">
                  <TransformComponent wrapperStyle={wrapperStyle}>
                    {!isLoading && (
                      <img
                        id="mapImage"
                        src={mapImage}
                        alt="Map of the Beechworth Historic Precinct"
                        className="pointer-events-none animate-fadeinMap"
                        style={{
                          maxWidth: `${mapWidth}px`,
                        }}
                        onAnimationEnd={() => {
                          setMapFadedIn(true);
                          zoomToCourthouse();
                        }}
                      />
                    )}
                  </TransformComponent>
                  {mapFadedIn && (
                    <>
                      <div
                        id="markers"
                        className="animate-fadeinMapControls absolute z-10 left-0 top-0 w-full h-full pointer-events-none overflow-hidden"
                      >
                        <Loader isLoading={!mapLoaded}>
                          {data.map((d, index) => {
                            const markerX =
                              (d.position[0] / 100) * mapWidth * curScale;
                            const markerY =
                              (d.position[1] / 100) * mapHeight * curScale;
                            const translatedX = markerX + xpos;
                            const translatedY = markerY + ypos;
                            return (
                              <Marker
                                filtered={
                                  filter !== null && d.node_type !== filter
                                }
                                key={index}
                                xpos={translatedX}
                                ypos={translatedY}
                                nodeType={d.node_type}
                                onClick={() =>
                                  handleMarkerClicked(
                                    index,
                                    d,
                                    -markerX,
                                    -markerY
                                  )
                                }
                              />
                            );
                          })}
                          {targetIndex != null &&
                            mapFadedIn &&
                            nodeData !== null &&
                            data?.map((d, index) => {
                              return (
                                targetIndex === index && (
                                  <TitleButton
                                    xpos={xpos}
                                    ypos={ypos}
                                    scale={curScale}
                                    data={d}
                                    onClick={() => openModal()}
                                    key={index}
                                  />
                                )
                              );
                            })}
                        </Loader>
                      </div>
                      <div
                        id="interface"
                        className="absolute inset-y-0 right-0 h-full pointer-events-none z-20"
                      >
                        {!isLoading && (
                          <div className="h-full flex flex-wrap place-content-center p-2 animate-fadeinMapControls ">
                            <div
                              id="zoomButtons"
                              className="space-y-2 pointer-events-auto"
                            >
                              <MapZoomButton
                                disabled={isMaxScale}
                                onClick={() => zoomIn()}
                                whiteBg
                              >
                                <ZoomInIcon
                                  width="50px"
                                  height="50px"
                                  stroke="black"
                                  fill="none"
                                  strokeWidth={1.5}
                                />
                              </MapZoomButton>
                              <MapZoomButton
                                disabled={isMinScale}
                                onClick={() => zoomOut()}
                                whiteBg
                              >
                                <ZoomOutIcon
                                  width="50px"
                                  height="50px"
                                  fill="none"
                                  stroke="black"
                                  strokeWidth={1.5}
                                />
                              </MapZoomButton>
                            </div>
                          </div>
                        )}
                        <FilterMenu
                          options={filterOptions}
                          setFilter={setFilter}
                        />
                      </div>
                      <ItemModal
                        data={nodeData}
                        setShowExternal={setShowModal}
                        showModal={showModal}
                      />
                    </>
                  )}
                </div>
              );
            }}
          </TransformWrapper>
        )}
      </div>
    </Content>
  );
};

export default Map;
