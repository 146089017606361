import React, { createContext, useState, useContext, useMemo } from "react";

import { CircleButton } from "./";
import { ReactComponent as CloseIcon } from "../assets/icons/button_close.svg";

interface ChildrenProps {
  children: React.ReactNode;
}

const ModalContext = createContext<{ show: boolean; setShow: any } | null>(
  null
);

const useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error(
      "Modal components cannot be rendered outside the Modal component"
    );
  }

  return context;
};

const Toggle: React.FC<ChildrenProps> = ({ children }) => {
  const { setShow } = useModalContext();
  const handleToggle = () => setShow((prevShow: boolean) => !prevShow);
  return (
    <div className="" onClick={handleToggle}>
      {children}
    </div>
  );
};

const Title: React.FC<ChildrenProps> = ({ children }) => {
  return <h1 className="text-xl font-medium">{children}</h1>;
};

const Header: React.FC<ChildrenProps & { showBorder?: boolean }> = ({
  children,
  showBorder = false,
}) => {
  const { setShow } = useModalContext();
  const handleClose = () => setShow(false);
  return <div>{children}</div>;
};

const Body: React.FC<
  { className?: string; padding?: boolean } & ChildrenProps
> = ({ children, className, padding = true }) => {
  return <>{children}</>;
};

const Footer: React.FC<ChildrenProps & { showBorder?: boolean }> = ({
  children,
  showBorder = false,
}) => {
  return <div>{children}</div>;
};

interface IModalDisplayProps extends ChildrenProps {
  tailwindWidth?: string;
  tailwindHeight?: string;
  useCloseBtn?: boolean;
  blackBg?: boolean;
  showOnStartup?: boolean;
}

const ModalDisplay: React.FC<IModalDisplayProps> = ({
  children,
  tailwindWidth,
  tailwindHeight,
  useCloseBtn,
  blackBg,
  showOnStartup,
}): JSX.Element | null => {
  const { show, setShow } = useModalContext();
  const handleClose = () => setShow(showOnStartup);

  // useEffect(() => {
  //   if (show) {
  //     document.body.classList.add("overflow-hidden");
  //   } else {
  //     document.body.classList.remove("overflow-hidden");
  //   }
  // }, [show]);

  // Disable to prevent accidental user clicks
  const handleOutsideClick = (e: React.MouseEvent) => {
    if (e.currentTarget === e.target) setShow(false);
  };

  // const modalWidth = tailwindWidth
  const dialogClass =
    "relative border-0 outline-none overflow- focus:outline-none mx-auto " +
    (tailwindHeight || "h-modal") +
    " " +
    (tailwindWidth || "w-1/2") +
    " " +
    (blackBg ? "bg-black" : "bg-bgSolid");

  return show ? (
    <>
      <div
        id="modal"
        className="z-40 animate-fadein overflow-y-auto overflow-x-hidden block fixed inset-0 flex flex-wrap place-content-center"
        onClick={handleOutsideClick}
      >
        <div id="modal-dialog" className={dialogClass}>
          {useCloseBtn && (
            <CircleButton
              className="absolute z-10 right-0 mt-4 mr-4 opacity-80"
              onClick={handleClose}
            >
              <CloseIcon
                stroke="white"
                strokeWidth={1.5}
                width="50px"
                height="50px"
              />
            </CircleButton>
          )}
          {children}
        </div>
      </div>
      <div className="z-30 animate-fadein transition-opacity opacity-80 fixed inset-0 bg-black" />
    </>
  ) : null;
};

type ModalContainerChildren = { Display: typeof ModalDisplay } & {
  Title: typeof Title;
} & { Header: typeof Header } & {
  Body: typeof Body;
} & { Footer: typeof Footer } & { Toggle: typeof Toggle };

/**
 * Please use showModal and setShowExternal if you intend to use parent component
 * to handle the open/closing of modal
 */
const ModalContainer: React.FC<
  ChildrenProps & {
    showModal?: boolean;
    setShowExternal?: Function | undefined;
  }
> &
  ModalContainerChildren = ({ children, showModal, setShowExternal }) => {
  const [show, setShow] = useState(false);

  const contextValue = useMemo(
    () => ({
      show: showModal ?? show,
      setShow: setShowExternal ?? setShow,
    }),
    [showModal, show]
  );

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
};

ModalContainer.Title = Title;
ModalContainer.Body = Body;
ModalContainer.Header = Header;
ModalContainer.Footer = Footer;
ModalContainer.Toggle = Toggle;
ModalContainer.Display = ModalDisplay;

ModalDisplay.defaultProps = {
  useCloseBtn: true,
};

export default ModalContainer;
